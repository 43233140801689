<template>
  <div>
    <div class="container">
      <div class="form-box">
        <el-form
          ref="ruleFormRef"
          :model="form"
          :rules="rules"
          class="demo-ruleForm"
          :size="formSize"
          status-icon
       >
       <!-- 请选择组织 -->
        <!-- <el-form-item label="选择组织" prop="choose">
          <el-input v-model="form.choose" readonly   placeholder="请选择所属组织" @click="visible = true"></el-input>
        </el-form-item> -->
        <!-- 班级名称 -->
        <el-form-item label="班次名称" prop="name">
           <el-input v-model="form.name"  placeholder="请填写班次名称"></el-input>
        </el-form-item>
        <!-- 收费金额 -->
        <el-form-item label="收费金额" prop="charge">
           <el-input v-model="form.charge" type="number" placeholder="请填写收费金额"></el-input>
        </el-form-item>
        <!-- 是否发布 -->
        <el-form-item label="是否发布" prop="genre">
          <el-select v-model="form.genre" placeholder="请选择是否发布">
            <el-option label="是" value="1" style="width:100%; text-align-last: center;"></el-option>
            <el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
          </el-select>
        </el-form-item>
        <!-- 缴费类型 -->
        <!-- <el-form-item label="缴费类型" prop="name">
          <el-select v-model="form.name" placeholder="请选择缴费类型">
            <el-option label="按月" value="1" style="width:100%; text-align-last: center;"></el-option>
            <el-option label="按年" value="2" ></el-option>
          </el-select>
        </el-form-item> -->
        <!-- 商户支付KEY -->
        <!-- <el-form-item label="商户支付KEY" prop="payment">
           <el-input v-model="form.payment"  placeholder="请填写商户支付KEY"></el-input>
        </el-form-item> -->
        <!-- 缴费说明 -->
        <!-- <el-form-item label="缴费说明" prop="remark">
           <el-input type="textarea" v-model="form.remark"  placeholder="请填写缴费说明"></el-input>
        </el-form-item> -->
        <!-- 提交按钮 -->
        <div class="submit">
          <el-form-item>
            <el-button type="primary" :loading="loa" @click="onSubmit">提交</el-button>
          </el-form-item>
        </div>
        <div>
          <s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
            <el-tree
              :data="texture"
              :props="defaultProps"
              accordion
              @node-click="dendrogram"
            />
          </s3-layer>
        </div>
      </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref,reactive,onMounted } from "vue";
import { ZClist,ZNgradeadd} from '../../utils/api'
import { ElMessage } from "element-plus";
import qs from 'qs'
import { useRouter } from "vue-router";
export default {
  name:"ZNBJadd",
  setup() {
    onMounted(()=>{
      messge()
    })
    // 获取工会列表信息
    const messge=()=>{
      ZClist().then((res)=>{
        console.log(res)
        texture.value=res.data.data.manageUnionList
      })
    }
    // 所属工会选择
    const yes = () => {
      form.choose=options.value
      visible.value = false; 
    };
    // 获取工会code
    let gaincode=ref('')
    // 树形选择
    let options=ref('')
    let dendrogram = (node)=>{
      options.value=node.unionName
      form.choose=options.value
      gaincode.value=node.unionCode
    }
    const defaultProps = {
        children: 'unionDtoList',
        label: 'unionName',
     }
    let texture =ref([])
    const visible = ref(false);
    // 表单内容
    const ruleFormRef = ref(null)
    const form = reactive({
      name:"",
      genre:"",
      // choose:"",
      // payment:"",
      // remark:"",
      charge:''
    })
    // 表单提交
    const router = useRouter();
    const loa =ref(false)
    const onSubmit = ()=>{
      console.log('班级名称',form.name)
      console.log('是否发布',form.genre)
      console.log('收费金额',form.charge)
      loa.value=true
      // 表单校验
      ruleFormRef.value.validate((valid)=>{
        if(valid==true){
          let data = {
            "className":form.name,
            "cost":form.charge,
            "state":form.genre,
            "childCustodyId":sessionStorage.getItem('ZNclasswide')
          }
          ZNgradeadd(qs.parse(data)).then((res)=>{
            console.log(res)
            if(res.data.code==0){
              ElMessage({
                message: "提交成功",
                type: "success",
                center: true,
              });
              setTimeout(()=>{
                router.push({ name: 'ZNclasswide', params: { userId: 1 }});
                // gaincode.value=''
                form.genre=''
                form.name=''
                form.charge=''
                // form.remark=''
                // form.choose=''
                loa.value=false
              },500)
            }
          })
          console.log('成功')
        }else if(valid==false){
          ElMessage({
            message: "提交失败",
            type: "error",
            center: true,
          });
          loa.value=false
        }
      })
    }
    // 验证表单提示
    const rules ={
      name:[
        { required: true, message: "请填写班次名称", trigger: "blur" },
      ],
      genre:[
        { required: true, message: "请选择是否发布", trigger: "blur" },
      ],
      charge:[
        { required: true, message: "请填写收费金额", trigger: "blur" },
      ]
      // choose:[
      //   { required: true, message: "请选择所属组织", trigger: "change" },
      // ],
      // payment:[
      //   { required: true, message: "请填写商户支付KEY", trigger: "change" },
      // ],
      // remark:[
      //   { required: true, message: "请填写备注", trigger: "change" },
      // ],
    }
    return {
      // 提交按钮
      loa,
      // 树形选择内容
      yes,
      visible,
      dendrogram,
      defaultProps,
      texture,
      // 表单内容
      ruleFormRef,
      form,
      // 表单提交按钮
      onSubmit,
      rules
    }
  },
}
</script>>

<style lang='scss' scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button { 
    -webkit-appearance: none !important; 
}
::v-deep .el-form-item__label{
  width: 108px;
}
::v-deep .el-form-item__label{
  font-weight: bold;
}
::v-deep .el-button{
  width: 100px;
  height: 40px;
  font-size: 16px;
}
::v-deep .submit{
  margin-left: 110px;
}
::v-deep .el-input{
  height: 44px;
}
::v-deep .el-form-item{
  margin-bottom: 30px;
  align-items: center;
}
::v-deep .el-tree-node__content{
  font-weight: 500;
}
::v-deep .el-select-dropdown__item.hover{
  background-color: #ffffff;
}
::v-deep .el-select-dropdown__wrap{
  background-color: #ffffff;
}
::v-deep .form-box{
  width: 500px;
}
::v-deep .el-form-item__content{
  margin-left: 0 !important;
}
::v-deep .container{
  max-width: 91%;
  margin: auto;
  margin-top: 10px;
  padding: 30px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}
</style>